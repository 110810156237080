(function () {
  'use strict';

  /* @ngdoc object
   * @name inscription.inscribedTeams
   * @description
   *
   */
  angular
    .module('neo.home.phases.phase.inscribedTeams', [
    ]);
}());
